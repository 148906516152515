@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Playfair+Display:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Playfair+Display:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Playfair+Display:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Playfair+Display:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Playfair+Display:wght@900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.main-text {
  font-family: "Playfair Display", serif;
  font-size: 80px;
  text-align: left; }

.h1 {
  font-size: 116px;
  font-family: "Playfair Display"; }

.h2 {
  font-family: "Playfair Display";
  font-size: 72px; }

.h3 {
  font-size: 36px;
  font-family: "Poppins Bold"; }

.h4 {
  font-size: 24px; }

.big-body-font {
  font-size: 20px; }

body {
  margin: 0;
  font-family: "Circular Book", "Avenir", "Open Sans", sans-serif;
  color: #202d31; }

.page-container {
  padding: 0 15%; }

.project-page-container {
  display: inline-block; }

@keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-transition: all;
  transition: all; }

h1 {
  font-family: "Playfair Display"; }

h2 {
  font-family: "Poppins";
  font-weight: bold; }

.App {
  width: 100%;
  position: relative;
  overflow: auto; }

.bg {
  background-color: #E5E5F7;
  opacity: 0.1;
  background-image: -webkit-linear-gradient(60deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), -webkit-linear-gradient(300deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), -webkit-linear-gradient(60deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), -webkit-linear-gradient(300deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), -webkit-linear-gradient(30deg, #444CF777 25%, transparent 25.5%, transparent 75%, #444CF777 75%, #444CF777), -webkit-linear-gradient(30deg, #444CF777 25%, transparent 25.5%, transparent 75%, #444CF777 75%, #444CF777);
  background-image: linear-gradient(30deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(150deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(30deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(150deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(60deg, #444CF777 25%, transparent 25.5%, transparent 75%, #444CF777 75%, #444CF777), linear-gradient(60deg, #444CF777 25%, transparent 25.5%, transparent 75%, #444CF777 75%, #444CF777);
  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px; }

.main-text {
  margin: 80px 0 0 80px;
  color: #272231;
  /* text-shadow: 2px 1px 4px #1b0538; */ }

.sub-header {
  margin: 20px 0 0 80px;
  font-size: 25px;
  font-weight: 600;
  color: #272231; }

.accordion {
  margin-left: 80px;
  width: 50%; }

.accordion__item {
  padding: 10px;
  border: solid 2px black;
  box-shadow: 6px 6px 0px 0px black;
  margin: 10px 0;
  cursor: pointer; }
  .accordion__item :focus {
    border: none;
    outline: none; }

.accordion__panel p {
  background: black;
  color: white;
  padding: 10px; }

.blog-list {
  width: 30%;
  float: left;
  position: relative;
  top: 50px;
  left: 0; }

.copy {
  margin-left: 80px; }

body {
  padding: 25px; }

.timeline {
  white-space: nowrap;
  overflow-x: scroll;
  padding: 30px 0 10px 0;
  position: relative; }

.entry {
  display: inline-block;
  vertical-align: top;
  background: black;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  position: relative;
  border-top: 4px solid #06182e;
  border-radius: 3px;
  min-width: 200px;
  max-width: 500px;
  margin: 10px 0 0 40px; }

.entry img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px; }

.entry:after {
  content: "";
  display: block;
  background: #eee;
  width: 7px;
  height: 7px;
  border-radius: 6px;
  border: 3px solid #06182e;
  position: absolute;
  left: 50%;
  top: -30px;
  margin-left: -6px; }

.entry:before {
  content: "";
  display: block;
  background: #06182e;
  width: 5px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: -20px;
  margin-left: -2px; }

.entry h1 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px; }

.entry h2 {
  letter-spacing: 0.2em;
  margin-bottom: 0;
  font-size: 14px; }

.entry p {
  margin: 0; }

.bar {
  height: 4px;
  background: black;
  width: 100%;
  position: relative;
  top: 22px;
  left: 0; }

.imgs {
  margin-left: 80px; }
  .imgs img {
    height: 200px;
    display: inline-block;
    margin: 10px 20px 10px 0;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%); }

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.main-text {
  font-family: "Playfair Display", serif;
  font-size: 80px;
  text-align: left; }

.h1 {
  font-size: 116px;
  font-family: "Playfair Display"; }

.h2 {
  font-family: "Playfair Display";
  font-size: 72px; }

.h3 {
  font-size: 36px;
  font-family: "Poppins Bold"; }

.h4 {
  font-size: 24px; }

.big-body-font {
  font-size: 20px; }

body {
  margin: 0;
  font-family: "Circular Book", "Avenir", "Open Sans", sans-serif;
  color: #202d31; }

.page-container {
  padding: 0 15%; }

.project-page-container {
  display: inline-block; }

@keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-transition: all;
  transition: all; }

h1 {
  font-family: "Playfair Display"; }

h2 {
  font-family: "Poppins";
  font-weight: bold; }

.body-text {
  font-size: 18px;
  padding: 20px 0;
  width: 100%;
  margin: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.main-text {
  font-family: "Playfair Display", serif;
  font-size: 80px;
  text-align: left; }

.h1 {
  font-size: 116px;
  font-family: "Playfair Display"; }

.h2 {
  font-family: "Playfair Display";
  font-size: 72px; }

.h3 {
  font-size: 36px;
  font-family: "Poppins Bold"; }

.h4 {
  font-size: 24px; }

.big-body-font {
  font-size: 20px; }

body {
  margin: 0;
  font-family: "Circular Book", "Avenir", "Open Sans", sans-serif;
  color: #202d31; }

.page-container {
  padding: 0 15%; }

.project-page-container {
  display: inline-block; }

@keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-transition: all;
  transition: all; }

h1 {
  font-family: "Playfair Display"; }

h2 {
  font-family: "Poppins";
  font-weight: bold; }

.full {
  width: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.main-text {
  font-family: "Playfair Display", serif;
  font-size: 80px;
  text-align: left; }

.h1 {
  font-size: 116px;
  font-family: "Playfair Display"; }

.h2 {
  font-family: "Playfair Display";
  font-size: 72px; }

.h3 {
  font-size: 36px;
  font-family: "Poppins Bold"; }

.h4 {
  font-size: 24px; }

.big-body-font {
  font-size: 20px; }

body {
  margin: 0;
  font-family: "Circular Book", "Avenir", "Open Sans", sans-serif;
  color: #202d31; }

.page-container {
  padding: 0 15%; }

.project-page-container {
  display: inline-block; }

@keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-transition: all;
  transition: all; }

h1 {
  font-family: "Playfair Display"; }

h2 {
  font-family: "Poppins";
  font-weight: bold; }

.project-title {
  margin-top: 50px; }
  .project-title p {
    display: inline-block;
    margin: 0; }
  .project-title img {
    height: 50px; }

.project-overview-row {
  display: grid;
  grid-template-columns: 1fr 7fr;
  width: 100%;
  margin-bottom: 20px; }

.project-page-container {
  width: 60%;
  float: right;
  margin-right: 5%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.main-text {
  font-family: "Playfair Display", serif;
  font-size: 80px;
  text-align: left; }

.h1 {
  font-size: 116px;
  font-family: "Playfair Display"; }

.h2 {
  font-family: "Playfair Display";
  font-size: 72px; }

.h3 {
  font-size: 36px;
  font-family: "Poppins Bold"; }

.h4 {
  font-size: 24px; }

.big-body-font {
  font-size: 20px; }

body {
  margin: 0;
  font-family: "Circular Book", "Avenir", "Open Sans", sans-serif;
  color: #202d31; }

.page-container {
  padding: 0 15%; }

.project-page-container {
  display: inline-block; }

@keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-transition: all;
  transition: all; }

h1 {
  font-family: "Playfair Display"; }

h2 {
  font-family: "Poppins";
  font-weight: bold; }

