.copy {
  margin-left: 80px;
}

//Timeline
body {
  padding: 25px;
}

.timeline {
  white-space: nowrap;
  overflow-x: scroll;
  padding: 30px 0 10px 0;
  position: relative;
}

.entry {
  display: inline-block;
  vertical-align: top;
  background: black;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  position: relative;
  border-top: 4px solid #06182e;
  border-radius: 3px;
  min-width: 200px;
  max-width: 500px;
  margin: 10px 0 0 40px;
}

.entry img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.entry:after {
  content: "";
  display: block;
  background: #eee;
  width: 7px;
  height: 7px;
  border-radius: 6px;
  border: 3px solid #06182e;
  position: absolute;
  left: 50%;
  top: -30px;
  margin-left: -6px;
}

.entry:before {
  content: "";
  display: block;
  background: #06182e;
  width: 5px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: -20px;
  margin-left: -2px;
}

.entry h1 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.entry h2 {
  letter-spacing: 0.2em;
  margin-bottom: 0;
  font-size: 14px;
}

.entry p {
  margin: 0;
}

.bar {
  height: 4px;
  background: black;
  width: 100%;
  position: relative;
  top: 22px;
  left: 0;
}

//img

.imgs {
  margin-left: 80px;
  img {
    height: 200px;
    display: inline-block;
    margin: 10px 20px 10px 0;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}
