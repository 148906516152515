@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Playfair+Display:wght@900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-text {
  font-family: "Playfair Display", serif;
  font-size: 80px;
  text-align: left;
}

$header-font: "Playfair Display";
$main-font: "Poppins";
$main-font-bold: "Poppins Bold";

$font-default: #202d31;
$secondary-color: #ffa6a6;

$page-padding: 0 15%;
$project-padding: 0 25%;

$font-body: 18px;
$font-body-big: 20px;
$font-h1: 116px;
$font-h2: 72px;
$font-h3: 36px;
$font-h4: 24px;

.h1 {
  font-size: $font-h1;
  font-family: $header-font;
}

.h2 {
  font-family: $header-font;
  font-size: $font-h2;
}

.h3 {
  font-size: $font-h3;
  font-family: $main-font-bold;
}

.h4 {
  font-size: $font-h4;
}

.big-body-font {
  font-size: $font-body-big;
}

body {
  margin: 0;
  font-family: "Circular Book", "Avenir", "Open Sans", sans-serif;
  color: $font-default;
}

.page-container {
  padding: $page-padding;
}

.project-page-container {
  display: inline-block;
}

// Animations!

@keyframes fadeInUp {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  transition: all;
}

h1 {
  font-family: $header-font;
}

h2 {
  font-family: $main-font;
  font-weight: bold;
}
