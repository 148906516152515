@import "./../../index.scss";

.project-title {
  margin-top: 50px;
  p {
    display: inline-block;
    margin: 0;
  }
  img {
    height: 50px;
  }
}

.project-overview-row {
  display: grid;
  grid-template-columns: 1fr 7fr;
  width: 100%;
  margin-bottom: 20px;
}

.project-page-container {
  width: 60%;
  float: right;
  margin-right: 5%;
}
