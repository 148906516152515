.App {
  width: 100%;
  position: relative;
  overflow: auto;
  }

  .bg {
    background-color: #E5E5F7;
opacity: 0.1;
background-image:  linear-gradient(30deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(150deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(30deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(150deg, #444CF7 12%, transparent 12.5%, transparent 87%, #444CF7 87.5%, #444CF7), linear-gradient(60deg, #444CF777 25%, transparent 25.5%, transparent 75%, #444CF777 75%, #444CF777), linear-gradient(60deg, #444CF777 25%, transparent 25.5%, transparent 75%, #444CF777 75%, #444CF777);
background-size: 80px 140px;
background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;

  }

.main-text {
  margin: 80px 0 0 80px;
  color: rgb(39, 34, 49);
  /* text-shadow: 2px 1px 4px #1b0538; */
}

.sub-header {
  margin: 20px 0 0 80px;
  font-size: 25px;
  font-weight: 600;
  color: rgb(39, 34, 49);
}

.accordion {
  margin-left: 80px;
  width: 50%;
}

.accordion__item {
  padding: 10px;
  border: solid 2px black;
  -webkit-box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 1);
  margin: 10px 0;
  cursor: pointer;
  :focus {
    border: none;
    outline: none;
  }
}
.accordion__panel p {
  background: black;
  color: white;
  padding: 10px;
}

.blog-list {
  width: 30%;
  float: left;
  position: relative;
  top: 50px;
  left: 0;
}
